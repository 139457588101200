<template>
	<div v-if="customers === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else class="customer-list">
		<div v-if="customers.length == 0" class="no-customers">
			There are no customers to display.
		</div>
		<table v-else class="data-table" :class="{'selecting-customer': selectedManageCustomerId !== null}">
			<tr>
				<template v-if="customerType == 'corporate'">
					<th>Company Name</th>
					<th>Business Number</th>
					<th>Billing Email</th>
					<th>Contact Phone</th>
				</template>
				<template v-else-if="customerType == 'residential'">
					<th>Customer Name</th>
					<th>Email Address</th>
					<th>Phone Number</th>
					<th>Date of Birth</th>
				</template>
				<th>Status</th>
				<th v-if="canEdit || canManage" colspan="3">{{((canEdit && canManage) || canDelete) ? 'Actions' : (canEdit ? 'Edit' : 'Manage')}}</th>
			</tr>
			<tr v-for="(customer, index) in customers" :class="`customer-status-${customer.status}`">
				<template v-if="customerType == 'corporate'">
					<td>{{customer.company_details.company_name}}</td>
					<td>{{customer.company_details.business_number.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}</td>
					<td>{{customer.contact_details.billing_email}}</td>
					<td>
						<phone-display :value="customer.contact_details.phone_number"></phone-display>
					</td>
				</template>
				<template v-else-if="customerType == 'residential'">
					<td>{{`${customer.customer_details.first_name} ${customer.customer_details.last_name}`.trim()}}</td>
					<td>{{customer.contact_details.email_address}}</td>
					<td>
						<phone-display :value="customer.contact_details.phone_number"></phone-display>
					</td>
					<td>{{formatDate(customer.customer_details.dob)}}</td>
				</template>
				<selectable-value-cell :record-index="index" :selectable-values="validStatuses(customer)" :selecting-record-index="changeStatusIndex" :is-selecting="changingStatus" :allow-selection="canChangeStatus && customer.status != 'cancelled'" :is-error="changeStatusErrors[index]" selectable-value-class-prefix="customer-status" @show-selection="display => changeStatusIndex = display ? index : null" @save-selection="changeStatus">{{capitaliseFirstLetter(customer.status)}}</selectable-value-cell>
				<td v-if="canEdit" class="edit-cell" @click="$emit('edit-customer', customer)" title="Edit Customer">&#x1F589;</td>
				<td v-if="canManage" class="action-cell manage-customer" @click="$emit('manage-customer', customer.id)" title="Manage Customer">
					<span v-show="selectedManageCustomerId != customer.id">&#x2699;</span>
					<img src="@/assets/images/loading.gif" v-show="selectedManageCustomerId == customer.id">
				</td>
				<td v-if="canDelete" class="delete-cell" @click="$emit('delete-customer', {customer, customerType})" title="Delete Customer">X</td>
			</tr>
		</table>
		<pagination v-model="displayPage" :data="customers" @page-changed="$emit('page-changed')"></pagination>
	</div>
	<cancel-customer-confirmation v-if="cancellingCustomer" :customer="cancellingCustomer" :customer-type="customerType" @close="cancellingCustomer = null" @completed="$emit('reload-customer-list')"></cancel-customer-confirmation>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import PhoneDisplay from '@/components/PhoneNumbers/PhoneDisplay';
	import SelectableValueCell from '@/components/SelectionCells/SelectableValueCell';
	import CancelCustomerConfirmation from '@/components/Modals/Customers/CancelCustomerConfirmation';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			modelValue: Number,
			customers: Array,
			customerType: String,
			selectedManageCustomerId: String
		},
		emits: ['update:modelValue', 'customer-status-changed', 'edit-customer', 'delete-customer', 'reload-customer-list', 'page-changed', 'manage-customer'],
		data() {
			return {
				changeStatusIndex: null,
				changingStatus: false,
				changeStatusErrors: {},
				cancellingCustomer: null
			}
		},
		computed: {
			displayPage: { // Enables the use of the v-model directive with this component.
				get() {
					return this.modelValue;
				},
				set(value) {
					this.$emit('update:modelValue', value);
				}
			},
			checkStandardPermission() { // Defines the condition for whether to check the user permission for editing or managing customers. Internal users always require the permission, but customer users only require it when they are managing a corporate customer, because that means they're displaying child customers (in any other case they're displaying customers that they have direct access to).
				return (this.isInternalUser || (this.isManagingCustomer && this.managingCustomer.customer_type == 'corporate'));
			},
			canEdit() { // Used to determine whether the authenticated user has the appropriate permission to edit customers. For internal users this is always based on the permission, but for customer users the edit option is only require it when they are managing a corporate customer, because that means they're displaying child customers (in any other case they're displaying customers to manage, not to edit).
				return this.checkStandardPermission ? this.hasPermission('customers', 'edit') : false;
			},
			canManage() { // Used to determine whether the authenticated user has the appropriate permission to manage customers. Internal users always require the permission, but customer users only require it when they are managing a corporate customer, because that means they're displaying child customers (in any other case they're displaying customers that they have direct access to).
				return this.checkStandardPermission ? this.hasPermission('customers', 'manage') : true;
			},
			canDelete() { // Used to determine whether the authenticated user has the appropriate permission to delete customers.
				return this.hasPermission('customers', 'delete');
			},
			canChangeStatus() { // Used to determine whether the authenticated user has the appropriate permission to change the status of customers.
				return this.hasPermission('customers', 'change-status');
			},
			...mapGetters(['hasPermission', 'isInternalUser', 'isManagingCustomer', 'managingCustomer'])
		},
		components: {
			Pagination, PhoneDisplay, SelectableValueCell, CancelCustomerConfirmation
		},
		methods: {
			async changeStatus(newStatus) { // Performs the API request to update the status for the customer identified by the changeStatusIndex property.
				const customer = this.customers[this.changeStatusIndex];
				if(newStatus != customer.status) { // Only continue if the selected status is different from the current status.
					if(newStatus == 'cancelled') { // If the "cancelled" status was selected, display a confirmation message.
						this.cancellingCustomer = customer;
						this.changeStatusIndex = null;
					} else { // For any other status, perform the status change immediately.
						// Mark the status change as pending to display the loading indicator, and remove the status change error if it is displayed.
						this.changingStatus = true;
						this.changeStatusErrors[this.changeStatusIndex] = false;
						
						try {
							// Perform the API request to update the status of the given customer.
							await this.HTTP.post(`customers/${customer.id}/status`, {status: newStatus});
							if(customer == this.customers[this.changeStatusIndex]) { // Send an event to the parent component to update the status displayed for the customer (only if the list of customers hasn't been refreshed since this API request was initiated).
								this.$emit('customer-status-changed', {customerType: this.customerType, customerIndex: this.changeStatusIndex, newStatus: newStatus});
							}
						} catch(error) { // If there was an error updating the customer status, handle it as appropriate depending on the error message (only if the list of customers hasn't been refreshed since this API request was initiated).
							if(customer == this.customers[this.changeStatusIndex]) {
								if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The new status must be different from the current status.") { // If the error is that the new status must be different from the current status, then it simply means that the status has changed since the customer list was loaded, so just quietly update the status that is displayed.
									this.$emit('customer-status-changed', {customerType: this.customerType, customerIndex: this.changeStatusIndex, newStatus: newStatus});
								} else { // For any other error, display the error message below the status name.
									this.changeStatusErrors[this.changeStatusIndex] = true;
								}
							}
						} finally { // Regardless of whether the API request was successful, hide both the status dropdown and loading indicator.
							this.changingStatus = false;
							this.changeStatusIndex = null;
						}
					}
				} else { // If the selected status is the same as the current status, simply hide the status dropdown.
					this.changeStatusIndex = null;
				}
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			formatDate(date) { // Given a date in YYYY-MM-DD format, returns the same date converted to D-MM-YYYY format.
				date = new Date(date);
				const day = date.getDate();
				const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months start at 0, so we need to add 1, then add a leading zero if applicable.
				const year = date.getFullYear();
				
				return `${day}-${month}-${year}`;
			},
			validStatuses(customer) { // Returns the list of customer statuses formatted for use in the user type selection cells.
				// Set the list of valid values for the underlying API.
				const apiValues = ['provisioning', 'active', 'suspended', 'cancelled'];
				
				// Create an object to map each underlying API value to the display value, which is simply the API value with the first character capitalised..
				const values = {};
				for(const value of apiValues) {
					if(value != 'provisioning' || customer.status == 'provisioning') { // The provisioning status is only included if the customer is currently in the provisioning status.
						values[value] = this.capitaliseFirstLetter(value);
					}
				}
				
				return values;
			}
		},
		watch: {
			customers: function(value) { // When the customers array changes, if the list of customers was cleared, clear the state of this component related to status changes, to ensure that the current values don't affect the new customer list when it is loaded.
				if(value === null) {
					this.changeStatusIndex = null;
					this.changingStatus = false;
					this.changeStatusErrors = {};
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.no-customers {
		text-align:center;
	}
	
	.customer-list {
		width:max-content;
		margin:0 auto;
	}
	
	.customer-status-provisioning, .customer-status-provisioning:deep(.phone-display input), .selection-cell:deep(.customer-status-provisioning) {
		color:#62A8FF;
	}
	
	.customer-status-suspended, .customer-status-suspended:deep(.phone-display input), .selection-cell:deep(.customer-status-suspended) {
		color:#FF0000;
	}
	
	.customer-status-cancelled, .customer-status-cancelled:deep(.phone-display input), .selection-cell:deep(.customer-status-cancelled) {
		color:var(--inactive-record-color);
	}
	
	.selection-cell:deep(.customer-status-active) {
		color:var(--main-accent-color);
	}
	
	.manage-customer {
		background-color:#90D345;
		color:#FFFFFF;
		font-size:2rem!important;
		
		img {
			width:20px;
		}
	}
	
	table.selecting-customer .manage-customer {
		cursor:default;
	}
</style>