<template>
	<modal-view :title="(this.delete ? 'Delete' : 'Cancel') + ' Customer Confirmation'" @close="$emit('close')" class="form">
		<div v-if="error" class="critical-error">{{error}}</div>
		<div v-else-if="cancelling || loadingCustomer" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="completed" class="success-message">
			The customer has been {{this.delete ? 'deleted' : 'cancelled'}} successfully.
		</div>
		<div v-else>
			<p>You are about to {{this.delete ? 'delete' : 'cancel'}} the customer <span id="customer-name">{{getCustomerName(customer)}}</span>. <span v-if="childCustomers.length > 0">This customer has child customers that will also be {{this.delete ? 'deleted' : 'cancelled'}}.</span> This cannot be undone. Are you sure you want to continue?</p>
			<ul v-if="!loadingCustomer">
				<li v-for="childCustomer in childCustomers">{{getCustomerName(childCustomer)}}</li>
			</ul>
			<button type="button" class="danger" @click="processAction">{{this.delete ? 'Delete' : 'Cancel'}} Customer</button>
			<button type="button" @click="$emit('close')">Cancel</button>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	
	export default {
		props: {
			customer: Object,
			customerType: String,
			delete: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				childCustomers: null,
				cancelling: false,
				completed: false,
				error: null
			}
		},
		computed: {
			loadingCustomer() { // Used to determine whether the customer's child customer list is being loaded from the API.
				return (this.childCustomers === null);
			}
		},
		async created() { // When the modal is loaded, get the list of child customers for the given customer.
			await this.loadChildCustomers();
		},
		components: {
			ModalView
		},
		methods: {
			async loadChildCustomers() { // Performs the API requests to get the list of child customers for the given customer.
				try {
					const statusOption = !this.delete ? '&status=current' : ''; // When cancelling a customer, we only need to list active children. When deleting a customer, we need to list all children.
					const response = await this.HTTP.get(`customers/${this.customer.id}/children?mode=descendants${statusOption}&limit=1000`);
					this.childCustomers = response.data.data;
				} catch(error) { // If there was an error obtaining the list of child customers, display an error message.
					this.error = 'An error has occurred.';
				}
			},
			async processAction() { // Performs the API request to cancel or delete the given customer.
				// Replace the confirmation message with a loading indicator.
				this.cancelling = true;
				
				try {
					// Perform the API request to cancel or delete the given customer.
					this.delete ? await this.deleteCustomer() : await this.cancelCustomer();
					
					// If the customer was cancelled or deleted successfully, display the success message and instruct the parent component to reload the customer list.
					this.completed = true;
					this.$emit('completed');
				} catch(error) { // If there was an error cancelling the customer, handle it as appropriate depending on the error message.
					if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The new status must be different from the current status.") { // If the error is that the new status must be different from the current status, then it simply means that the customer has already been cancelled, so just act as if the request was successful.
						this.completed = true;
						this.$emit('completed');
					} else if(error.response && error.response.status == 400 && error.response.data && (error.response.data.error == "The given customer has services and can't be deleted." || error.response.data.error == "A child customer of the given customer has services and can't be deleted." || error.response.data.error == "The given customer is assigned to an agent and can't be deleted." || error.response.data.error == "A child customer of the given customer is assigned to an agent and can't be deleted.")) { // If the error is that the given customer has services or is assigned to an agent and can't be deleted, display the specific error message from the API response.
						this.error = error.response.data.error;
					} else { // For any other error, display an error message.
						this.error = 'An error has occurred.';
					}
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.cancelling = false;
				}
			},
			async cancelCustomer() { // Performs the API request to cancel the given customer.
				await this.HTTP.post(`customers/${this.customer.id}/status`, {status: 'cancelled', recursive: true});
			},
			async deleteCustomer() { // Performs the API request to delete the given customer.
				await this.HTTP.delete(`customers/${this.customer.id}?recursive=yes`);
			},
			getCustomerName(customer) { // Returns the customer name for the given customer, depending on the customer type.
				switch(this.customerType) {
					case 'corporate':
						return customer.company_details.company_name;
					case 'residential':
						return `${customer.customer_details.first_name} ${customer.customer_details.last_name}`.trim();
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#customer-name {
		font-weight:bold;
	}
	
	button {
		margin-top:20px;
		margin-right:20px;
		min-width:100px;
	}
</style>