<template>
	<h1>{{(!isInternalUser && !isManagingCustomer) ? 'Customer Selection' : ((!isInternalUser && managingResidentialCustomer) ? 'Corporate Customers' : 'Manage Customers')}}</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else :class="{'customers-wrapper': (this.availableCustomerType != 'all')}">
		<div v-if="isInternalUser" id="filters" class="form">
			<label for="customer-status-selection">Status</label>
			<select id="customer-status-selection" v-model="displayCustomerStatus" @change="loadCustomers">
				<option value="all">All</option>
				<option value="current">Current</option>
				<option value="provisioning">Provisioning</option>
				<option value="active">Active</option>
				<option value="suspended">Suspended</option>
				<option value="cancelled">Cancelled</option>
			</select>
			<button v-if="canCreate && (!this.isManagingCustomer || this.managingCustomer.status == 'active')" type="button" @click="createCustomer">Create New Customer</button>
		</div>
		<tabs id="customer-list">
			<template v-for="(customerList, customerType) in {corporate: corporateCustomers, residential: residentialCustomers}">
				<tab v-if="availableCustomerType == 'all' || availableCustomerType == customerType" :title="capitaliseFirstLetter(customerType)">
					<customer-type-list v-model="displayPage[customerType]" :customers="customerList" :customer-type="customerType" :selected-manage-customer-id="selectedManageCustomerId" @customer-status-changed="customerStatusChanged" @edit-customer="editCustomer" @delete-customer="deleteCustomer" @manage-customer="manageCustomer" @reload-customer-list="getCustomersOfType(customerType, response => this[customerType + 'Customers'] = response)" @page-changed="loadCustomers(customerType)"></customer-type-list>
				</tab>
			</template>
		</tabs>
		<edit-customer-form v-if="editingCustomer" :customer-id="editedCustomer" @close="editingCustomer = false" @completed="customerType => getCustomersOfType(customerType, response => this[customerType + 'Customers'] = response)"></edit-customer-form>
		<cancel-customer-confirmation v-if="deletingCustomer" :customer="deletingCustomer" :customer-type="deletingCustomer.customer_type" :delete="true" @close="deletingCustomer = null" @completed="getCustomersOfType(deletingCustomer.customer_type, response => this[deletingCustomer.customer_type + 'Customers'] = response)"></cancel-customer-confirmation>
	</div>
</template>

<script>
	import Tab from '@/components/Tabs/Tab';
	import Tabs from '@/components/Tabs/Tabs';
	import CustomerTypeList from '@/components/CustomerTypeList';
	import EditCustomerForm from '@/components/Modals/Customers/EditCustomerForm';
	import CancelCustomerConfirmation from '@/components/Modals/Customers/CancelCustomerConfirmation';
	import {mapState, mapGetters, mapActions} from 'vuex';
	
	export default {
		data() {
			return {
				corporateCustomers: null,
				residentialCustomers: null,
				displayCustomerStatus: 'current',
				dataError: false,
				editingCustomer: false,
				editedCustomer: null,
				deletingCustomer: null,
				selectedManageCustomerId: null,
				displayPage: {
					corporate: 1,
					residential: 1
				}
			}
		},
		computed: {
			canCreate() { // Used to determine whether the authenticated user has the appropriate permission to create customers.
				return this.hasPermission('customers', 'create');
			},
			availableCustomerType() { // Used to determine which customer types the authenticated user has access to view.
				if(this.isInternalUser) { // For internal users, is they're managing a customer, only display child customers of the same customer, so we only include one customer type. If they're not managing a customer, use the customer types that the user has access to.
					return this.isManagingCustomer ? this.managingCustomer.customer_type : this.user.customer_access;
				} else if(this.isManagingCustomer) { // For customer users, the customer list only ever displays corporate customers while they're managing a customer, since residential customers are displayed in the dropdown menu below the Pennytel logo.
					return 'corporate';
				} else { // For customer users that aren't managing a customer, the list depends on which customer types the user has access to.
					const hasCorporateCustomers = (this.user.corporate_customers > 0);
					const hasResidentialCustomers = (Object.keys(this.user.residential_customers).length > 0);
					if(hasCorporateCustomers && hasResidentialCustomers) {
						return 'all';
					} else {
						return hasCorporateCustomers ? 'corporate' : 'residential';
					}
				}
			},
			managingResidentialCustomer() { // Used to determine whether the user is currently managing a residential customer.
				return (this.managingCustomer !== null && this.managingCustomer.customer_type == 'residential');
			},
			displayChildCustomers() { // Used to determine whether to display child customers for the customer that the user is currently managing (this isn't the case if a customer user is managing a residential customer, in which case we're still just displaying corporate customers).
				return (this.isManagingCustomer && (this.isInternalUser || this.managingCustomer.customer_type == 'corporate'));
			},
			...mapState(['user']),
			...mapGetters(['hasPermission', 'isInternalUser', 'isManagingCustomer', 'managingCustomer'])
		},
		components: {
			Tabs, Tab, CustomerTypeList, EditCustomerForm, CancelCustomerConfirmation
		},
		created() { // When the page is loaded, get the list of customers.
			this.loadCustomers();
		},
		methods: {
			loadCustomers(customerType = null) { // Performs the API requests to get the list of customers.
				// If a specific customer type wasn't provided, reset the display page for both customer types to Page 1.
				if(typeof customerType !== 'string') {
					this.displayPage.corporate = 1;
					this.displayPage.residential = 1;
					customerType = null; // Some calls to this function are triggered by an event, so the first argument to this function is the event. Reset it back to NULL.
				}
				
				// Set the customer list to NULL to display the loading indicator.
				if(customerType == null || customerType == 'corporate') {
					this.corporateCustomers = null;
				}
				if(customerType == null || customerType == 'residential') {
					this.residentialCustomers = null;
				}
				
				// Perform the API requests to get the list of customers for each customer type.
				this.getCustomersOfType('corporate', response => this.corporateCustomers = response);
				this.getCustomersOfType('residential', response => this.residentialCustomers = response);
			},
			getCustomersOfType(customerType, callback) { // Performs the API request to get the list of customers for a particular type, if the authenticated user has access to view customers of the given type.
				if(this.availableCustomerType == 'all' || this.availableCustomerType == customerType) {
					const urlParameters = this.displayChildCustomers ? `/${this.managingCustomer.id}/children` : ''; // If the user is currently managing a customer, what we need to obtain is the child customers of the given customer.
					const queryString = (this.isInternalUser && this.displayCustomerStatus != 'all') ? `&status=${this.displayCustomerStatus}` : ''; // Sets the query string argument to filter the customer list to the appropriate status. If no filter should be applied, the query string is omitted.
					return this.HTTP.get('customers/' + customerType + urlParameters + `?page=${this.displayPage[customerType]}` + queryString).then(result => callback(result.data.data)).catch(error => this.dataError = true);
				}
			},
			customerStatusChanged({customerType, customerIndex, newStatus}) { // Called when the status of a customer is changed, to update the status displayed in the table.
				const customer = (customerType == 'corporate') ? this.corporateCustomers[customerIndex] : this.residentialCustomers[customerIndex];
				customer.status = newStatus;
			},
			createCustomer() { // Displays the modal for creating a new customer.
				this.editedCustomer = null;
				this.editingCustomer = true;
			},
			editCustomer(customer) { // Displays the edit customer modal for the given customer.
				this.editedCustomer = customer.id;
				this.editingCustomer = true;
			},
			deleteCustomer({customer, customerType}) { // Displays the deletion confirmation modal for given customer.
				customer.customer_type = customerType;
				this.deletingCustomer = customer;
			},
			manageCustomer(customerId) { // Sets the customer that the user is currently managing.
				if(this.selectedManageCustomerId === null) { // Only continue if there isn't already a request in progress to manage a customer.
					this.selectedManageCustomerId = customerId;
					this.setManagingCustomer({customerId});
				}
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			...mapActions(['setManagingCustomer'])
		}
	}
</script>

<style scoped lang="scss">
	#filters {
		float:right;
		
		label {
			display:block;
		}
		
		button {
			margin-left:20px;
		}
	}
	
	#customer-list {
		clear:both;
	}
	
	.customers-wrapper {
		width:max-content;
		margin:0 auto;
		
		#filters {
			margin-bottom:20px;
		}
		
		&:deep(.tab) {
			padding:0;
		}
	}
</style>