<template>
	<modal-view :title="creatingCustomer ? 'Create Customer' : 'Edit Customer'" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="savingCustomer || loadingCustomer || loadingAgentList" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="customerSaved" class="success-message">Customer {{creatingCustomer ? 'Created' : 'Updated'}} Successfully</div>
		<div v-else>
			<form class="data-form" @submit.prevent="saveCustomer">
				<div v-if="creatingCustomer && availableCustomerType == 'all' && !isManagingCustomer">
					<label for="customer-type">Customer Type</label>
					<span class="required">*</span>
					<select id="customer-type" v-model="customerType" @change="errorMessage = null">
						<option value="" disabled v-if="customerType == ''" v-once>Select...</option>
						<option value="corporate">Corporate</option>
						<option value="residential">Residential</option>
					</select>
				</div>
				<div>
					<label for="assigned-agent-selection">Agent</label>
					<v-select id="assigned-agent-selection" v-model="selectedAgentId" :options="formatAgentsListForDropdown()" :reduce="agent => agent.id" :clearable="false">
						<template v-slot:no-options>No matching agents found.</template>
					</v-select>
				</div>
				<div v-if="creatingCustomer && this.canViewUsers" v-show="customerType == 'residential'">
					<label for="customer-user">Customer User</label>
					<select id="customer-user" v-model="userSelectionType" @change="loadUserList">
						<option value="new">Create New User</option>
						<option value="existing">Use Existing User</option>
					</select>
					<div v-show="userSelectionType == 'existing'">
						<div v-if="userList === null" class="loading-indicator" id="user-list-loading-indicator">
							<img src="@/assets/images/loading.gif">
						</div>
						<v-select v-else id="customer-user-selection" v-model="existingUserIndex" :options="formatUserListForDropdown()" :reduce="user => user.index" :selectable="user => (user.index !== null)" :clearable="false">
							<template v-slot:no-options>No matching users found.</template>
						</v-select>
					</div>
				</div>
				<fieldset v-show="customerType == 'corporate'">
					<legend>Company Details</legend>
					<div>
						<label for="company-name">Company Name</label>
						<span class="required">*</span>
						<input type="text" id="company-name" v-model="companyName" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="business-number">Business Number</label>
						<span class="required">*</span>
						<input type="text" id="business-number" v-model="businessNumber" @input="errorMessage = null" maxlength="11" />
					</div>
				</fieldset>
				<fieldset v-show="customerType == 'residential'">
					<legend>Customer Details</legend>
					<div>
						<label for="customer-title">Title</label>
						<span class="required">*</span>
						<select id="customer-title" v-model="title" @change="errorMessage = null">
							<option value="" disabled v-if="title == ''" v-once>Select...</option>
							<option>Mr</option>
							<option>Mrs</option>
							<option>Miss</option>
							<option>Ms</option>
						</select>
					</div>
					<div>
						<label for="first-name">First Name</label>
						<span class="required">*</span>
						<input type="text" id="first-name" v-model="firstName" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="last-name">Last Name</label>
						<span class="required">*</span>
						<input type="text" id="last-name" v-model="lastName" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="dob">Date of Birth</label>
						<span class="required">*</span>
						<date-picker v-model:value="dob" format="D-MM-YYYY" title-format="D-MM-YYYY" value-type="YYYY-MM-DD" :input-attr="{id: 'dob'}" :clearable="false" @change="errorMessage = null" />
					</div>
				</fieldset>
				<fieldset>
					<legend>Contact Details</legend>
					<div>
						<label for="email-address">{{customerType == 'corporate' ? 'Billing Email' : 'Email Address'}}</label>
						<span class="required">*</span>
						<input type="text" id="email-address" v-model="emailAddress" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="customer-phone-number">Phone Number</label>
						<span class="required">*</span>
						<phone-input id="customer-phone-number" v-model="phoneNumber" @input="errorMessage = null"></phone-input>
					</div>
				</fieldset>
				<fieldset>
					<legend>Address</legend>
					<div>
						<label for="country">Country</label>
						<span class="required">*</span>
						<select id="country" v-model="country" @change="errorMessage = null">
							<option value="" disabled v-if="country == ''" v-once>Select...</option>
							<option value="AU">Australia</option>
						</select>
					</div>
					<div>
						<label for="address-line-1">Address Line 1</label>
						<span class="required">*</span>
						<input type="text" id="address-line-1" v-model="addressLine1" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="address-line-2">Address Line 2</label>
						<input type="text" id="address-line-2" v-model="addressLine2" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="suburb">Suburb</label>
						<span class="required">*</span>
						<input type="text" id="suburb" v-model="suburb" @input="errorMessage = null" maxlength="255" />
					</div>
					<div>
						<label for="state">State</label>
						<span class="required">*</span>
						<select id="state" v-model="state" @change="errorMessage = null">
							<option value="" disabled>Select...</option>
							<option v-for="(name, abbreviation) in stateList" :value="abbreviation">{{name}}</option>
						</select>
					</div>
					<div>
						<label for="post-code">Post Code</label>
						<span class="required">*</span>
						<input type="text" id="post-code" v-model="postCode" @input="errorMessage = null" maxlength="255" />
					</div>
				</fieldset>
				<div class="button-wrapper">
					<button type="submit">{{creatingCustomer ? 'Create' : 'Save'}} Customer</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import PhoneInput from '@/components/PhoneNumbers/PhoneInput';
	import DatePicker from 'vue-datepicker-next';
	import vSelect from 'vue-select';
	import {mapState, mapGetters} from 'vuex';
	
	export default {
		props: {
			customerId: String
		},
		data() {
			return {
				customer: null,
				customerType: '',
				companyName: '',
				businessNumber: '',
				title: '',
				firstName: '',
				lastName: '',
				dob: '',
				emailAddress: '',
				phoneNumber: '',
				addressLine1: '',
				addressLine2: '',
				suburb: '',
				state: '',
				postCode: '',
				country: '',
				userSelectionType: 'new',
				existingUserIndex: null,
				selectedAgentId: null,
				userList: null,
				stateList: null,
				agentList: null,
				savingCustomer: false,
				customerSaved: false,
				errorMessage: null,
				dataError: false
			}
		},
		computed: {
			creatingCustomer() { // Used to determine whether a new customer is being created, or an existing customer is being updated.
				return (this.customerId === null);
			},
			loadingCustomer() { // Used to determine whether the updated customer details are being loaded from the API.
				return (!this.creatingCustomer && this.customer === null);
			},
			loadingAgentList() { // Used to determine whether the agent list is being loaded from the API.
				return (this.agentList === null);
			},
			availableCustomerType() { // Used to determine which customer types the authenticated user has access to create.
				return this.user.customer_access;
			},
			canViewUsers() { // Used to determine whether the authenticated user has the appropriate permission to assign an existing user to a customer.
				return this.hasPermission('users', 'view');
			},
			canManageInternal() { // Used to determine whether the authenticated user has the appropriate permission to manage internal users.
				return this.hasPermission('users', 'manage-internal');
			},
			...mapState(['user']),
			...mapGetters(['hasPermission', 'isManagingCustomer', 'managingCustomer'])
		},
		components: {
			ModalView, PhoneInput, DatePicker, vSelect
		},
		async created() { // When the modal is loaded, check if we are editing an existing customer, and if so, populate the form with the existing customer details.
			if(!this.creatingCustomer) {
				await this.getCustomerDetails();
			} else if(this.isManagingCustomer) { // If we're creating a child customer, set the customer type to the same customer type as the parent customer.
				this.customerType = this.managingCustomer.customer_type;
			} else if(this.availableCustomerType != 'all') { // If we're creating a new root customer, check if the authenticated user has access to create any customer type. If not, automatically set the customer type to create.
				this.customerType = this.availableCustomerType;
			}
			
			// Load the list of agents from the API.
			await this.loadAgentList();
		},
		methods: {
			async getCustomerDetails() { // Performs the API request to get the customer details for the given customer.
				try {
					const response = await this.HTTP.get('customers/' + this.customerId);
					this.customer = response.data.data;
					
					this.customerType = this.customer.customer_type;
					this.phoneNumber = this.customer.contact_details.phone_number;
					this.addressLine1 = this.customer.address.address_line_1;
					this.addressLine2 = this.customer.address.address_line_2;
					this.suburb = this.customer.address.suburb;
					this.state = this.customer.address.state;
					this.postCode = this.customer.address.post_code;
					this.country = this.customer.address.country;
					this.selectedAgentId = this.customer.agent_id;
					
					switch(this.customerType) {
						case 'corporate':
							this.companyName = this.customer.company_details.company_name;
							this.businessNumber = this.customer.company_details.business_number;
							this.emailAddress = this.customer.contact_details.billing_email;
							break;
						case 'residential':
							this.title = this.customer.customer_details.title;
							this.firstName = this.customer.customer_details.first_name;
							this.lastName = this.customer.customer_details.last_name;
							this.dob = this.customer.customer_details.dob;
							this.emailAddress = this.customer.contact_details.email_address;
							break;
					}
				} catch(error) { // If there was an error obtaining the customer details, display the generic error message.
					this.dataError = true;
				}
			},
			async loadUserList() { // Performs the API request to get the list of users.
				this.errorMessage = null; // Since this method is called when the value of a dropdown menu is changed, we need to clear any error messages, just like we do for any other field.
				if(this.userSelectionType == 'existing' && this.userList === null) { // The user list only needs to be loaded once, the first time "Use Existing User" is selected, so if it's already been loaded, or "Create New User" is selected, we don't need to do anything.
					try {
						const queryString = this.canManageInternal ? '&access-type=customer' : ''; // Sets query string argument to only return customer users, if the user has access to set this parameter.
						const response = await this.HTTP.get('users?limit=1000' + queryString);
						this.userList = response.data.data;
					} catch(error) { // If there was an error obtaining the user list, display the generic error message.
						this.dataError = true;
					}
				}
			},
			async loadAgentList() { // Performs the API request to get the list of agents.
				try {
					const response = await this.HTTP.get('agents?details=basic&limit=1000');
					this.agentList = response.data.data;
				} catch(error) { // If there was an error obtaining the agent list, display the generic error message.
					this.dataError = true;
				}
			},
			async saveCustomer() { // Performs the API request to create or update the given customer.
				if(this.validateForm()) { // Only attempt to perform the API request if the form has been filled out correctly.
					try {
						// Replace the edit customer form with a loading indicator.
						this.savingCustomer = true;
						
						// Perform the API request to create or update the given customer.
						const data = this.setCustomerData();
						(this.creatingCustomer) ? await this.createCustomer(data) : await this.updateCustomer(data);
						
						// If the customer was updated successfully, display the success message and instruct the parent component to reload the customer list.
						this.customerSaved = true;
						this.$emit('completed', this.customerType);
					} catch(error) { // If there was an error saving the customer, display an error message below the edit customer form.
						this.setErrorMessage(error);
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.savingCustomer = false;
					}
				}
			},
			setCustomerData() { // Returns an object to use in the API requests for creating or updating the given customer.
				// Set the fields that are common to both corporate and residential customers.
				const data = {
					contact_details: {
						phone_number: this.phoneNumber
					},
					address: {
						address_line_1: this.addressLine1,
						address_line_2: this.addressLine2,
						suburb: this.suburb,
						state: this.state,
						post_code: this.postCode,
						country: this.country
					},
					agent_id: this.selectedAgentId
				};
				
				// Set the fields that are specific to either corporate or residential customers.
				switch(this.customerType) {
					case 'corporate':
						data.contact_details.billing_email = this.emailAddress;
						data.company_details = {
							company_name: this.companyName,
							business_number: this.businessNumber
						};
						break;
					case 'residential':
						data.contact_details.email_address = this.emailAddress;
						data.customer_details = {
							title: this.title,
							first_name: this.firstName,
							last_name: this.lastName,
							dob: this.dob
						};
						break;
				}
				
				// If we're creating a new customer, set the customer type.
				if(this.creatingCustomer) {
					data.customer_type = this.customerType;
					if(this.customerType == 'residential' && this.userSelectionType == 'existing') { // If we're assigning an existing user to the customer, also add the User ID.
						data.user_id = this.userList[this.existingUserIndex].id;
					}
				}
				
				return data;
			},
			async createCustomer(data) { // Performs the API request to create a customer with the given data.
				const apiEndpoint = this.isManagingCustomer ? `customers/${this.managingCustomer.id}/children` : 'customers'; // If the user is currently managing a customer, create a child customer. Otherwise simply create a new root customer.
				await this.HTTP.post(apiEndpoint, data);
			},
			async updateCustomer(data) { // Performs the API request to update the given customer with the given data.
				await this.HTTP.put('customers/' + this.customerId, data);
			},
			validateForm() { // Validates the data provided in the form.
				// Validate that if the user type is set to existing user, that a user was actually selected.
				if(this.customerType == 'residential' && this.userSelectionType == 'existing' && this.existingUserIndex === null) {
					this.errorMessage = 'No existing user selected.';
					return false;
				}
				
				// Set the list of required fields, depending on the customer type.
				let requiredFields = {'customer type': this.customerType, 'phone number': this.phoneNumber, 'address': this.addressLine1, 'suburb': this.suburb, 'state': this.state, 'post code': this.postCode, 'country': this.country};
				switch(this.customerType) {
					case 'corporate':
						requiredFields = {'company name': this.companyName, 'business number': this.businessNumber, 'billing email': this.emailAddress, ...requiredFields};
						break;
					case 'residential':
						requiredFields = {'title': this.title, 'first name': this.firstName, 'last name': this.lastName, 'date of birth': this.dob, 'email address': this.emailAddress, ...requiredFields};
						break;
				}
				
				// Validate that all required fields in the edit form have been filled in.
				for(const field in requiredFields) {
					const value = requiredFields[field];
					if(value == '' && value !== false) { // For the phone number field, if the value entered is invalid, it's returned as FALSE. This is validated separately, so we want to ignore it here.
						this.errorMessage = `The customer's ${field} is required.`;
						return false;
					}
				}
				
				// Validate that the phone number field is valid.
				if(this.phoneNumber === false) {
					this.errorMessage = "The phone number provided is invalid.";
					return false;
				}
				
				return true;
			},
			setErrorMessage(error) { // Sets the error message below the edit customer form, based on the error returned by the API request.
				if(error.response && error.response.status == 400) { // A 400 status code indicates a validation error, so we should display the error returned by the API. However, for some error messages, we need to replace them with a more human friendly error message.
					const errorMessage = error.response.data.error;
					switch(errorMessage) {
						case "The 'contact_details.email_address' field must be a valid email address.":
							this.errorMessage = 'The email address provided is invalid.';
							break;
						case "The 'contact_details.billing_email' field must be a valid email address.":
							this.errorMessage = 'The billing email provided is invalid.';
							break;
						case "The value provided for 'company_details.business_number' is invalid.":
							this.errorMessage = 'The business number provided is invalid.';
							break;
						case "Child customers can only be created for customers that are in the 'active' status.":
							this.errorMessage = 'Child customers can only be created for customers that are in the active status.';
							break;
						default:
							this.errorMessage = errorMessage;
					}
				} else { // Any other error is an internal error, so we should just display a generic error message.
					this.errorMessage = 'An error has occurred.';
				}
			},
			formatAgentsListForDropdown() { // Returns the list of agents formatted for use in the agent list dropdown menu.
				const agentList = this.agentList.map(function(agent){
					return {id: agent.id, label: agent.agent_name}
				});
				agentList.unshift({id: null, label: 'None'});
				return agentList;
			},
			formatUserListForDropdown() { // Returns the list of users formatted for use in the user list dropdown menu.
				const userList = this.userList.map(function(user, index){
					return {index: index, label: `${user.first_name} ${user.last_name}`.trim()}
				});
				userList.unshift({index: null, label: 'Select...'});
				return userList;
			}
		},
		watch: {
			existingUserIndex(value) { // When an existing user is selected to use for the customer, set the appropriate fields based on the existing user data.
				this.errorMessage = null; // Since this method is called when the value of a dropdown menu is changed, we need to clear any error messages, just like we do for any other field.
				const user = this.userList[value];
				
				this.title = user.title;
				this.firstName = user.first_name;
				this.lastName = user.last_name;
				this.emailAddress = user.email_address;
				this.phoneNumber = user.phone_number ?? ''; // The PhoneInput component doesn't work with NULL values, so we convert it to an empty string instead.
			},
			selectedAgentId(value) { // When an agent is selected to assign to the customer, we need to clear any error messages, just like we do for any other field.
				this.errorMessage = null;
			},
			country(value) { // Sets the list of valid states based on the selected country.
				switch(value) {
					case 'AU':
						this.stateList = {'ACT': 'Australian Capital Territory', 'NSW': 'New South Wales', 'NT': 'Northern Territory', 'QLD': 'Queensland', 'SA': 'South Australia', 'TAS': 'Tasmania', 'VIC': 'Victoria', 'WA': 'Western Australia'};
						break;
					default:
						this.stateList = null;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:60%;
		
		fieldset {
			padding:5% 10%;
		}
		
		input, select, .mx-datepicker {
			width:100%;
		}
		
		.mx-datepicker {
			display:block;
		}
	}
	
	#customer-user-selection {
		border-top:0;
	}
	
	#user-list-loading-indicator {
		margin-top:10px;
	}
</style>